import { useState, useCallback, useLayoutEffect } from 'react'
import Cookies from 'js-cookie'

export const useAuth = () => {
   const [token, setToken] = useState(null)
   const [userId, setUserId] = useState(null)
   const [username, setUsername] = useState('')

   const login = useCallback((jwtToken, id, name) => {
      setToken(jwtToken)
      setUserId(id)
      setUsername(name)
      Cookies.set('accessToken', jwtToken)
      Cookies.set('userId', id)
      Cookies.set('username', name)
   }, [])
   const logout = useCallback(() => {
      setToken(null)
      setUserId(null)
      setUsername(null)
      Cookies.remove('accessToken')
      Cookies.remove('userId')
      Cookies.remove('username')
   }, [])

   useLayoutEffect(() => {
      //Здесть использована лэйаут функция, чтобы при переходе на страницы не показывалась окно авторизации на долю секунды
      //Записываем данные и логинимся, если они есть в cookies, срабатывает при переходе на каждую страницу
      const token = Cookies.get('accessToken')
      const userId = Cookies.get('userId')
      const username = Cookies.get('username')
      if (token && userId && username) login(token, userId, username)
   }, [login])

   return { login, logout, token, userId, username }
}
